import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("employeeRoles.edit.title"),
            id: this.$route.params.id,
            employeeRole: {},
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        rangeLength: [3, 50]
                    },
                    description: {
                        maxLength: 500
                    }
                }
            }
        };
    },
    methods: {
        edit() {
            return this.$store.dispatch("employeeRole/edit", {
                id: this.id,
                employeeRole: this.employeeRole
            });
        },
        editSuccess() {
            this.$toast.showSuccessToast(this.$t("employeeRoles.edit.toastSuccessTitle"), this.$t("employeeRoles.edit.toastSuccessContent", {
                name: this.employeeRole.name
            }));
            return this.$router.push("/employee-roles");
        },
        cancel() {
            return this.$router.push("/employee-roles");
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.employeeRole = await this.$store.dispatch("employeeRole/get", {
                id: this.id
            });
            this.title += ` ${this.employeeRole.name}`;
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/employee-roles");
        }
    }
});
